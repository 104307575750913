import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/style/border.css'
import './assets/style/reset.css'
//import Vconsole from "vconsole"

import fastClick from 'fastclick'
fastClick.attach(document.body)

//Vue.prototype.$Vconsole = new Vconsole()

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
